import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axios';

// initialize an empty api service that we'll inject endpoints into later as needed
export const rootApi = createApi({
  tagTypes: [
    'Applications',
    'JobApplications',
    'ApplicationRequests',
    'AuthContext',
    'Documents',
    'ConnectionRequests',
    'Notifications',
    'Connections',
    'Comments',
    'Job',
    'DepartmentUsers',
    'Skills',
    'Cards',
    'Subscription',
    'Invoices',
    'JobLinkRequest',
    'LinkedAgencies',
    'JobInvites',
    'Department',
    'User',
    'Profile',
    'Clients',
    'Recruiters',
    'Account',
    'AccountSettings',
    'Checklist',
    'Stakeholders',
    'OrgInvites',
    'CompanyProfile',
    'Domains',
    'OrgContacts',
    'OrgCandidates',
    'IncomingCandidateInvites',
    'OutgoingCandidateInvites',
    'OrgSettings',
    'ProfileAvailability',
    'OrgDateSettings',
    'OrgIntegrations',
    'AvailableIntegrations',
    'JobOffers',
    'Offers',
    'IntegrationFieldMap',
    'IntegrationSettings',
    'ResourceRequests',
    'JobIntegrations',
    'JobSummary',
    'CandidateJobs',
    'JobActivities',
    'ApplicationActivities',
    'SystemSettings',
    'SystemTerms',
    'Placements',
    'GlobalOrganizations',
    'GlobalAccounts',
    'SystemDocuments',
    'ApplicationProfile',
    'Interviews',
    'InterviewsParticipants',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
  }),
  endpoints: () => ({}),
});

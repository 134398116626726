import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { isDate, isString } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Alert,
  Button,
  Dialog,
  Divider,
  Checkbox,
  Typography,
  IconButton,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  MobileStepper,
  FormHelperText,
  CircularProgress,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import humanizer from 'src/utils/humanizer';
import { URLRegex } from 'src/utils/data/regex';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { hideInterviewSchedulerModal } from 'src/store/slices/interviews/interviewSlice';
import { useGetApplicationByIdQuery } from 'src/services/applications/applications.service';
import {
  useGetInterviewByIdQuery,
  useUpdateInterviewMutation,
  useCancelInterviewMutation,
  useScheduleInterviewMutation,
  useGetPossibleInterviewParticipantsQuery,
} from 'src/services/interviews/interviews.service';
import {
  ParticpantAccount,
  TimeSelectionType,
  CreateInterviewDto,
  UpdateInterviewDto,
  InterviewLocationType,
  AvailabilityTimeslotDto,
  InterviewSubmitBehaviour,
} from 'src/services/interviews/interview.types';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import RHFNumberInput from 'src/components/hook-form/rhf-number-input';
import { RHFTextField, RHFAutocomplete, RHFVisibilityToggle } from 'src/components/hook-form';

import { TenantType } from 'src/types/enums';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const INTERVIEW_FREQUENCY_MS = 15 * 60 * 1000;

type Props = {
  applicationId: string;
};

type AvailabilityDateRange = {
  start_at: Date;
  end_at: Date;
};

export default function ScheduleInterviewModal({ applicationId }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const loaded = useBoolean(false);

  const dispatch = useAppDispatch();

  const [errorMessage, setErrowMessage] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();

  const authContext = useAuthContext();

  const interviewId = useAppSelector((state) => state.interviews.selectedInterviewId);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const { currentData: interview, isLoading: isLoadingInterview } = useGetInterviewByIdQuery(
    { interviewId: interviewId as string },
    { skip: !interviewId }
  );

  const { currentData: applicationData, isLoading: isLoadingApplication } =
    useGetApplicationByIdQuery(applicationId as string, { skip: !applicationId });

  const { currentData: availableParticipantsResponse, isFetching: isLoadingavailableParticipants } =
    useGetPossibleInterviewParticipantsQuery(
      { applicationId: applicationId as string },
      { skip: !applicationId }
    );

  const [createInterview] = useScheduleInterviewMutation();

  const [updateInterview] = useUpdateInterviewMutation();

  const [cancelInterview] = useCancelInterviewMutation();

  const minDate = useMemo(() => new Date(), []);

  const thisUser = useMemo(
    () => ({
      id: authContext.account.id,
      first_name: authContext.user.first_name,
      last_name: authContext.user.last_name,
      type: authContext.account.type,
    }),
    [
      authContext.account.id,
      authContext.account.type,
      authContext.user.first_name,
      authContext.user.last_name,
    ]
  );

  const availableParticipants = useMemo(
    () =>
      availableParticipantsResponse
        ? [
            ...availableParticipantsResponse.filter(
              (participant) => participant.type !== TenantType.Candidate
            ),
          ]
        : [],
    [availableParticipantsResponse]
  );

  const candidate = useMemo(
    () =>
      availableParticipantsResponse?.find(
        (participant) => participant.type === TenantType.Candidate
      ),
    [availableParticipantsResponse]
  );

  const AvailabilitySchema = Yup.object().shape({
    date: Yup.date()
      .required(t('validation.required'))
      .min(new Date(), t('validation.min_date_today'))
      .default(null),
    start_time: Yup.date()
      .required(t('validation.required'))
      .min(new Date(), t('validation.min_date_today'))
      .default(null),
    end_time: Yup.date()
      .required(t('validation.required'))
      .min(Yup.ref('start_time'), t('validation.min_end_date'))
      .default(null),
  });

  const Schema = Yup.object().shape({
    title: Yup.string().required(t('validation.required')),

    link: Yup.lazy((value) =>
      !value ? Yup.string() : Yup.string().matches(URLRegex, t('validation.url'))
    ),

    location: Yup.string(),

    duration_minutes: Yup.number().required(t('validation.required')),

    participants: Yup.array().required(t('validation.required')).min(1, t('validation.required')),

    availability: Yup.array().of(AvailabilitySchema),

    submit_behaviour: Yup.string(),

    // eslint-disable-next-line no-empty-pattern
    scheduled_at: Yup.date()
      .nullable()
      .when('submit_behaviour', ([submit_behaviour], schema) => {
        if (submit_behaviour === InterviewSubmitBehaviour.Schedule) {
          return schema.required(t('validation.required'));
        }
        return schema;
      }),

    location_type: Yup.boolean(), // false === online, true === offline
  });

  const defaultValues = {
    title: '',
    link: '',
    location: '',
    duration_minutes: INTERVIEW_FREQUENCY_MS / 60 / 1000,
    participants: [],
    availability: [],
    requestAvailability: false,
    scheduled_at: null,
    location_type: false,
    submit_behaviour: InterviewSubmitBehaviour.Schedule,
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { isValid, errors },
  } = methods;

  console.log(errors);
  console.log(isValid);

  const duration_minutes = watch('duration_minutes');

  const participants = watch('participants');

  const title = watch('title');

  const availability = watch('availability');

  console.log(availability);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'availability',
  });

  useEffect(() => {
    if (interview) {
      setValue('title', interview.title);
      setValue('link', interview.link ?? '');
      setValue('duration_minutes', interview.duration_minutes);
      setValue('participants', interview.participants);
    }
  }, [interview, setValue]);

  useEffect(() => {
    if (loaded.value || !availableParticipants?.length || !!participants?.length) return;

    loaded.onTrue();

    const thisUserAccount =
      availableParticipants?.find((participant) => participant.id === authContext.account.id) ??
      thisUser;

    setValue('participants', [thisUserAccount]);
  }, [
    authContext.account.id,
    loaded,
    participants?.length,
    setValue,
    availableParticipants,
    thisUser,
  ]);

  const generateIntervalDates = (
    date: Date,
    startTime: Date,
    endTime: Date,
    interviewDuration: number
  ): AvailabilityDateRange[] => {
    const interviewDurationMs = interviewDuration * 60 * 1000;
    const dates: AvailabilityDateRange[] = [];

    startTime = new Date(date);
    startTime.setHours(startTime.getHours());
    startTime.setMinutes(
      Math.floor(startTime.getMinutes() / interviewDuration) * interviewDuration
    );
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);

    while (startTime < endTime && startTime.getTime() + interviewDurationMs <= endTime.getTime()) {
      const start_at = new Date(startTime);
      const end_at = new Date(start_at.getTime() + interviewDurationMs);

      dates.push({
        start_at,
        end_at,
      });

      startTime = new Date(startTime.getTime() + INTERVIEW_FREQUENCY_MS);
    }

    return dates;
  };

  const generateNewTimeSlot = (selectedDate?: Date | null) => {
    const date = selectedDate || new Date(new Date().setHours(new Date().getHours() + 1, 0, 0, 0));

    append({
      date,
      start_time: new Date(new Date().setHours(date.getHours(), 0, 0, 0)),
      end_time: new Date(new Date().setHours(date.getHours() + 1, 0, 0, 0)),
    });
  };

  const onRequestAvailabilityClicked = () => {
    const selectedDate = watch('scheduled_at');

    generateNewTimeSlot(selectedDate);

    setCurrentPage(1);

    setValue('submit_behaviour', InterviewSubmitBehaviour.RequestAvailability);
  };

  const onBackClicked = () => {
    setCurrentPage(0);

    setValue('submit_behaviour', InterviewSubmitBehaviour.Schedule);
    reset({ availability: [] }, { keepValues: true });
  };

  const onSubmit = handleSubmit(async (formData) => {
    setErrowMessage('');

    const submitBehaviour = watch('submit_behaviour');

    const particpantsWithCandidate = [...participants, candidate];

    let availabilitySlots: AvailabilityTimeslotDto[] = [];

    if (formData.availability?.length) {
      const allAvailableSlots: AvailabilityDateRange[] = formData.availability.reduce(
        (
          slots: AvailabilityDateRange[],
          slot: { start_time: Date; end_time: Date; date: Date }
        ) => {
          const slotDates = generateIntervalDates(
            slot.date,
            slot.start_time,
            slot.end_time,
            formData.duration_minutes
          );
          return slots.concat(slotDates);
        },
        []
      );

      const mergedAvailabilitySlots = removeDuplicateDateRanges(allAvailableSlots);

      availabilitySlots = mergedAvailabilitySlots.map((slot: AvailabilityDateRange) => ({
        start_at: `${slot.start_at.toISOString().slice(0, 10)} ${slot.start_at
          .toTimeString()
          .slice(0, 5)}`,
        end_at: `${slot.end_at.toISOString().slice(0, 10)} ${slot.end_at
          .toTimeString()
          .slice(0, 5)}`,
      }));
    }

    if (interviewId) {
      const data: UpdateInterviewDto = {
        participants: particpantsWithCandidate.map((participant) => ({
          ...participant,
          tenant_type: participant.type,
        })),
        available_slots:
          submitBehaviour === InterviewSubmitBehaviour.RequestAvailability
            ? availabilitySlots
            : undefined,
      };

      onUpdate(data);
    } else {
      const scheduledUTC =
        submitBehaviour === InterviewSubmitBehaviour.Schedule
          ? `${(formData.scheduled_at as Date).toISOString().slice(0, 10)} ${(
              formData.scheduled_at as Date
            )
              .toTimeString()
              .slice(0, 5)}`
          : undefined;

      const data: CreateInterviewDto = {
        title: formData.title,
        link: !formData.location_type && formData.link ? formData.link : undefined,
        location: formData.location_type && formData.location ? formData.location : undefined,
        duration_minutes: formData.duration_minutes,
        scheduled_at: scheduledUTC,
        participants: particpantsWithCandidate.map((participant) => ({
          ...participant,
          tenant_type: participant.type,
        })),
        available_slots:
          submitBehaviour === InterviewSubmitBehaviour.RequestAvailability
            ? availabilitySlots
            : undefined,
        submit_behaviour: submitBehaviour as InterviewSubmitBehaviour,
        location_type: formData.location_type
          ? InterviewLocationType.IN_PERSON
          : InterviewLocationType.ONLINE,
      };

      onSchedule(data);
    }
  });

  const onSchedule = async (data: CreateInterviewDto) => {
    try {
      await createInterview({
        applicationId: applicationId as string,
        data,
      }).unwrap();

      enqueueSnackbar(t('applications.schedule_interview_modal.api.create.success'), {
        variant: 'success',
      });

      onCloseModal();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(t('applications.schedule_interview_modal.api.create.default_error'), {
        variant: 'error',
      });

      setErrowMessage(t('applications.schedule_interview_modal.api.create.default_error'));
    }
  };

  const onUpdate = async (data: UpdateInterviewDto) => {
    try {
      await updateInterview({
        interviewId: interviewId as string,
        data,
      }).unwrap();

      enqueueSnackbar(t('applications.schedule_interview_modal.api.update.success'), {
        variant: 'success',
      });

      onCloseModal();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(t('applications.schedule_interview_modal.api.update.default_error'), {
        variant: 'error',
      });

      setErrowMessage(t('applications.schedule_interview_modal.api.update.default_error'));
    }
  };

  const onCancelInterview = async () => {
    try {
      await cancelInterview({
        interviewId: interviewId as string,
      }).unwrap();

      enqueueSnackbar(t('applications.schedule_interview_modal.api.cancel.success'), {
        variant: 'success',
      });

      onCloseModal();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(t('applications.schedule_interview_modal.api.cancel.default_error'), {
        variant: 'error',
      });

      setErrowMessage(t('applications.schedule_interview_modal.api.cancel.default_error'));
    }
  };

  const onCloseModal = useCallback(() => {
    reset();
    setCurrentPage(0);
    loaded.onFalse();

    dispatch(hideInterviewSchedulerModal());
  }, [dispatch, loaded, reset]);

  const getModalAlertMessage = () => {
    if (interview?.notes) {
      return t(`applications.schedule_interview_modal.alert.changes_requested`);
    }

    if (tenant === TenantType.Client) {
      if (applicationData?.manager_type === TenantType.Client) {
        return t(`applications.schedule_interview_modal.alert.client_direct`);
      }

      return t(`applications.schedule_interview_modal.alert.client_managed`);
    }

    return t(`applications.schedule_interview_modal.alert.recruiter`);
  };

  // eslint-disable-next-line arrow-body-style
  const renderInitialForm = () => {
    const scheduled_at = watch('scheduled_at');

    const locationType = watch('location_type');

    return (
      <Stack>
        {getModalAlertMessage() && (
          <Alert
            variant="standard"
            severity={interview?.notes ? 'warning' : 'info'}
            sx={{ marginBottom: 2 }}
          >
            <Typography fontSize="1em" fontWeight={interview?.notes ? '600' : '500'}>
              {getModalAlertMessage()}
            </Typography>
            {interview?.notes && <Typography fontSize="1em">{interview?.notes}</Typography>}
          </Alert>
        )}
        <Stack direction="column" spacing={0}>
          <Typography variant="h6">
            {t('applications.schedule_interview_modal.interviewee_details.title')}
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ color: (theme) => theme.palette.grey[700] }}
          >
            {`${applicationData?.attached_profile?.first_name} ${applicationData?.attached_profile?.last_name}`}
          </Typography>
        </Stack>
        <Grid container spacing={1} paddingY={3}>
          <Grid xs={12}>
            <RHFTextField
              name="title"
              label={t('applications.schedule_interview_modal.form.title.label')}
              placeholder={t('applications.schedule_interview_modal.form.title.placeholder')}
              disabled={!!interviewId}
            />
          </Grid>

          <Grid xs={5} display="flex" alignItems="flex-end">
            {!locationType ? (
              <RHFTextField
                name="link"
                label={t('applications.schedule_interview_modal.form.link.label')}
                placeholder={t('applications.schedule_interview_modal.form.link.placeholder')}
                disabled={!!interviewId}
              />
            ) : (
              <RHFTextField
                name="location"
                label={t('applications.schedule_interview_modal.form.location.label')}
                placeholder={t('applications.schedule_interview_modal.form.location.placeholder')}
                disabled={!!interviewId}
              />
            )}
          </Grid>

          <Grid xs={2} display="flex" alignItems="flex-end">
            <RHFVisibilityToggle
              name="location_type"
              labelPosition="top"
              checked={locationType}
              onLabel={t('applications.schedule_interview_modal.form.location_type.on_label')}
              offLabel={t('applications.schedule_interview_modal.form.location_type.off_label')}
              disabled={!!interviewId}
            />
          </Grid>

          <Grid xs={5}>
            <RHFNumberInput
              name="duration_minutes"
              min={INTERVIEW_FREQUENCY_MS / 60 / 1000}
              max={480}
              step={INTERVIEW_FREQUENCY_MS / 60 / 1000}
              label={t('applications.schedule_interview_modal.form.duration_minutes.label')}
              disabled={!!interviewId}
            />
          </Grid>

          <Grid xs={12} display="flex" alignItems="flex-end">
            <RHFAutocomplete
              sx={{ marginTop: 'auto', width: '100%' }}
              name="participants"
              multiple
              disableCloseOnSelect
              label={t('applications.schedule_interview_modal.form.participants.label')}
              options={availableParticipants}
              renderOption={({ key, ...rest }, option: ParticpantAccount, { selected }) => (
                <li key={key} {...rest}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Stack direction="column">
                    <Typography variant="body1">
                      {`${option.first_name} ${option.last_name}`}
                    </Typography>
                    <Typography variant="body2">{t(`enums.tenant.${option.type}`)}</Typography>
                  </Stack>
                </li>
              )}
              getOptionLabel={(option) =>
                option && !isString(option) ? `${option.first_name} ${option.last_name}` : ''
              }
              isOptionEqualToValue={(option, value) => option && option.id === value.id}
              getOptionKey={(option) => (option as ParticpantAccount).id as string}
            />
          </Grid>

          <Grid xs={12} paddingTop={2} textAlign="center">
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.grey[100],
                padding: 2,
                borderRadius: 2,
              }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="subtitle1">
                  {`${t('common.select')} ${t('common.form_labels.date')}`}
                </Typography>

                <MobileDateTimePicker
                  orientation="portrait"
                  label={t('common.form_labels.date')}
                  value={scheduled_at}
                  disablePast
                  closeOnSelect
                  onChange={(newValue) => {
                    if (newValue) {
                      setValue(`scheduled_at`, newValue, { shouldValidate: true });
                    }
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  minDate={new Date(minDate.setHours(0, 0, 0, 0))}
                  disabled={interview?.time_selection_type === TimeSelectionType.SELECT}
                />

                <Divider>
                  <Typography variant="subtitle1">{t('common.or')}</Typography>
                </Divider>

                <Button
                  onClick={onRequestAvailabilityClicked}
                  fullWidth
                  variant="outlined"
                  disabled={
                    !participants?.length ||
                    !title ||
                    interview?.time_selection_type === TimeSelectionType.SCHEDULE
                  }
                >
                  {t(
                    `applications.schedule_interview_modal.${
                      interviewId ? 'update_availability' : 'request_availability'
                    }`
                  )}
                </Button>
              </Stack>
            </Box>
          </Grid>

          <Grid xs={12} paddingTop={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={interview?.time_selection_type === TimeSelectionType.SELECT}
            >
              {t('applications.schedule_interview_modal.title')}
            </Button>
          </Grid>

          {!!interviewId && (
            <Grid xs={12}>
              <Button fullWidth variant="contained" color="error" onClick={onCancelInterview}>
                {t('applications.schedule_interview_modal.form.cancel')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
    );
  };

  const renderRow = (index: number) => {
    const rowFields = watch(`availability.${index}`);

    if (!rowFields) return null;

    return (
      <Stack direction="row" spacing={1} mt={1} key={`row-${index}`}>
        <FormControl>
          <MobileDatePicker
            orientation="portrait"
            label={t('common.form_labels.date')}
            value={rowFields.date}
            disablePast
            onChange={(newValue) => {
              if (newValue) {
                const currentHours = rowFields.start_time.getHours();

                const updatedStartTimeDate = new Date();
                updatedStartTimeDate.setDate(newValue.getDate());
                updatedStartTimeDate.setHours(currentHours, 0, 0, 0);

                const updatedEndTimeDate = new Date();
                updatedEndTimeDate.setDate(newValue.getDate());
                updatedEndTimeDate.setHours(currentHours + 1, 0, 0, 0);

                setValue(`availability.${index}.date`, newValue, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
                setValue(`availability.${index}.start_time`, updatedStartTimeDate, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
                setValue(`availability.${index}.end_time`, updatedEndTimeDate, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
            minDate={minDate}
          />
          {errors?.availability?.[index]?.date?.message && (
            <FormHelperText error>{errors?.availability?.[index]?.date?.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <TimePicker
            label={t('common.form_labels.start_time')}
            minutesStep={duration_minutes}
            value={rowFields.start_time}
            // minTime={moment.tz(field.date.toDateString(), 'UTC').isBefore(moment()) ? new Date() : undefined}
            onChange={(newValue) =>
              isDate(newValue)
                ? setValue(`availability.${index}.start_time`, newValue, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  })
                : null
            }
          />
          {errors.availability?.[index]?.start_time?.message && (
            <FormHelperText error>
              {errors.availability?.[index]?.start_time?.message}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <TimePicker
            label={t('common.form_labels.end_time')}
            minutesStep={duration_minutes}
            value={rowFields.end_time}
            onChange={(newValue) =>
              isDate(newValue)
                ? setValue(`availability.${index}.end_time`, newValue, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  })
                : null
            }
          />
          {errors.availability?.[index]?.end_time?.message && (
            <FormHelperText error>{errors.availability?.[index]?.end_time?.message}</FormHelperText>
          )}
        </FormControl>

        <IconButton disabled={index === 0 && fields.length < 2} onClick={() => remove(index)}>
          <Iconify icon="eva:trash-2-fill" width={16} height={16} />
        </IconButton>
      </Stack>
    );
  };

  const renderCalendarView = (
    <Grid container>
      <Grid xs={12} sm={12}>
        <Grid xs={12} sx={{ mb: 1 }}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Button
              variant="text"
              onClick={onBackClicked}
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
            >
              {t('common.back')}
            </Button>

            <Typography variant="subtitle1">
              {t('applications.schedule_interview_modal.form.chosen_duration', {
                time: humanizer(duration_minutes * 60 * 1000, { units: ['h', 'm'], round: true }),
              })}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">
            {t('applications.schedule_interview_modal.choose_availability')}
          </Typography>
        </Grid>
        {/* <Grid xs={6} paddingY={2}>
          <RHFNumberInput
            name="duration_minutes"
            min={10}
            max={480}
            step={10}
            label={t('applications.schedule_interview_modal.form.duration_minutes.label')}
            disabled={!!interviewId}
          />
        </Grid> */}
        <Grid xs={12}>
          <Stack direction="column" spacing={1} alignItems="flex-start">
            {fields.map((_field, index) => renderRow(index))}

            <Button
              sx={{ mb: 1 }}
              variant="text"
              disabled={!isValid || fields.length === 10}
              onClick={() => generateNewTimeSlot()}
              startIcon={<Iconify icon="eva:plus-circle-fill" width={16} />}
            >
              {t('common.add')}
            </Button>
          </Stack>

          <Button type="submit" fullWidth variant="contained">
            {interviewId ? t('common.update') : t('common.invite')}
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12} sm={4} />
    </Grid>
  );

  const renderCurrentPage = () => {
    if (currentPage === 0) {
      return renderInitialForm();
    }

    return renderCalendarView;
  };

  return (
    <Dialog open onClose={onCloseModal} fullWidth maxWidth={currentPage === 0 ? 'sm' : 'sm'}>
      <DialogTitle sx={{ pb: 1 }}>{t(`applications.schedule_interview_modal.title`)}</DialogTitle>

      <DialogContent>
        {errorMessage && (
          <Alert variant="standard" severity="error" sx={{ marginBottom: 2 }}>
            {errorMessage}
          </Alert>
        )}

        {isLoadingavailableParticipants || isLoadingInterview ? (
          <Stack
            width="100%"
            minHeight={400}
            minWidth={400}
            justifyContent="center"
            alignItems="center"
            paddingTop={4}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderCurrentPage()}
          </FormProvider>
        )}

        <Stack paddingTop={2}>
          <MobileStepper
            steps={2}
            position="static"
            activeStep={currentPage}
            nextButton={undefined}
            backButton={undefined}
            sx={{ justifyContent: 'center' }}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCloseModal}>{t('common.close')}</Button>
      </DialogActions>

      {/* <ConfirmDialog
        open={showConfirm.value}
        onClose={showConfirm.onFalse}
        title={t('common.confirm')}
        content={getConfirmDialogContent()}
        action={<Button onClick={onConfirmAction}>{t('common.confirm')}</Button>}
      /> */}
    </Dialog>
  );
}

function removeDuplicateDateRanges(ranges: AvailabilityDateRange[]): AvailabilityDateRange[] {
  // Create a Map to store unique date ranges
  // We'll use string representation of dates as keys
  const uniqueRanges = new Map<string, AvailabilityDateRange>();

  ranges.forEach((range) => {
    // Create a key by combining start and end dates
    const key = `${range.start_at.getTime()}-${range.end_at.getTime()}`;

    // Only add if this combination hasn't been seen before
    if (!uniqueRanges.has(key)) {
      uniqueRanges.set(key, range);
    }
  });

  // Convert Map values back to array
  return Array.from(uniqueRanges.values());
}

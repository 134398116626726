import { useMemo, useState, useCallback } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import {
  Card,
  Chip,
  Link,
  Stack,
  Button,
  Divider,
  CardHeader,
  Typography,
  Pagination,
  CardContent,
  CardActions,
} from '@mui/material';

import humanizer from 'src/utils/humanizer';
import { fDateTime } from 'src/utils/format-time';
import isValidHttpUrl from 'src/utils/isValidUrl';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { InterviewStatus } from 'src/services/interviews/interview.types';
import { useGetApplicationInterviewsQuery } from 'src/services/interviews/interviews.service';
import {
  openInterviewActionModal,
  openInterviewSchedulerModal,
} from 'src/store/slices/interviews/interviewSlice';

import Iconify from 'src/components/iconify';

import { TenantType } from 'src/types/enums';

type Props = {
  applicationId: string;
};

export default function UpcomingInterviewCard({ applicationId }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const dispatch = useAppDispatch();

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    per_page: 1,
  });

  const { currentData: interviews, isLoading } = useGetApplicationInterviewsQuery({
    applicationId,
    params: {
      ...pageOptions,
      status: tenant === TenantType.Candidate ? InterviewStatus.Scheduled : undefined,
    },
  });

  const chosenInterview = useMemo(() => interviews?.results[0] ?? undefined, [interviews?.results]);

  const chosenInterviewStatus: InterviewStatus | undefined = useMemo(
    () => chosenInterview?.status,
    [chosenInterview]
  );

  const getSubheader = () => {
    switch (chosenInterviewStatus) {
      case InterviewStatus.Sent:
        return t(
          `enums.interview_status.sent_${tenant === TenantType.Candidate ? 'candidate' : 'org'}`
        );
      case InterviewStatus.ChangesRequested:
        return t('enums.interview_status.changes_requested');
      case InterviewStatus.RescheduleRequested:
        return t('enums.interview_status.reschedule_requested');
      case InterviewStatus.Scheduled:
        return fDateTime(chosenInterview?.scheduled_at);
      case InterviewStatus.Cancelled:
        return t('enums.interview_status.cancelled');
      case InterviewStatus.InReview:
        return t('enums.interview_status.in_review');
      default:
        return '';
    }
  };

  const getLinkText = () => {
    if (chosenInterview?.link) {
      if (isValidHttpUrl(chosenInterview.link, false)) {
        return (
          <Button
            LinkComponent={Link}
            href={chosenInterview.link}
            target="_blank"
            variant="text"
            color="primary"
            endIcon={<Iconify icon="solar:link-round-angle-bold-duotone" width={16} />}
          >
            {t('common.open')}
          </Button>
        );
      }

      return chosenInterview?.link;
    }

    if (chosenInterview?.location) return chosenInterview.location;

    return '-';
  };

  const onEdit = useCallback(() => {
    if (!chosenInterview) return;

    dispatch(
      openInterviewSchedulerModal({
        applicationId,
        interviewId: chosenInterview.id,
      })
    );
  }, [applicationId, chosenInterview, dispatch]);

  const onReview = useCallback(() => {
    if (!chosenInterview) return;

    dispatch(
      openInterviewActionModal({
        interviewId: chosenInterview.id,
      })
    );
  }, [chosenInterview, dispatch]);

  if (isLoading || !interviews) {
    // We don't want to show a loading placer as if there are no interviews
    // we don't want to show the card at all.
    return null;
  }

  const getActionButton = () => {
    if (chosenInterviewStatus === InterviewStatus.InReview && tenant === TenantType.Recruiter) {
      return (
        <Button
          onClick={onReview}
          variant="contained"
          size="small"
          startIcon={<Iconify icon="mdi:edit" />}
          disabled={
            !chosenInterviewStatus ||
            [InterviewStatus.Cancelled, InterviewStatus.Scheduled, InterviewStatus.Sent].includes(
              chosenInterviewStatus
            )
          }
        >
          {t('common.review')}
        </Button>
      );
    }

    if (
      chosenInterviewStatus === InterviewStatus.ChangesRequested &&
      tenant === TenantType.Client
    ) {
      return (
        <Button
          onClick={onEdit}
          variant="contained"
          size="small"
          startIcon={<Iconify icon="mdi:edit" />}
          disabled={
            !chosenInterviewStatus ||
            [InterviewStatus.Cancelled, InterviewStatus.Scheduled, InterviewStatus.Sent].includes(
              chosenInterviewStatus
            )
          }
        >
          {t('common.edit')}
        </Button>
      );
    }

    return null;
  };

  return (
    <Card>
      <CardHeader
        title={t('applications.detail.candidate_profile.upcoming_interviews.title')}
        subheader={getSubheader()}
        avatar={<Iconify icon="solar:calendar-mark-broken" />}
        action={getActionButton()}
      />

      <CardContent sx={{ pt: 1 }}>
        <Divider />
        <Grid container spacing={1} justifyContent="space-between" paddingTop={2}>
          <Grid xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              {t('common.title')}
            </Typography>
            <Typography variant="body2">{chosenInterview?.title ?? '-'}</Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              {t('applications.detail.candidate_profile.upcoming_interviews.link')}
            </Typography>
            <Typography variant="body2">{getLinkText()}</Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              {t('applications.detail.candidate_profile.upcoming_interviews.duration')}
            </Typography>
            <Typography variant="body2">
              {chosenInterview?.duration_minutes
                ? humanizer(chosenInterview.duration_minutes * 60 * 1000)
                : '-'}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="body1" fontWeight="bold">
              {t('applications.detail.candidate_profile.upcoming_interviews.participants')}
            </Typography>
            <Stack direction="row" flexWrap="wrap" spacing={1} paddingTop={1}>
              {chosenInterview?.participants.map((participant) => (
                <Chip
                  variant="outlined"
                  key={participant.id}
                  label={`${participant.first_name} ${participant.last_name}`}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Stack direction="row" spacing={1} display="flex" justifyContent="center" width="100%">
          <Pagination
            page={pageOptions.page || 1}
            count={Math.ceil((interviews?.count || 1) / pageOptions.per_page)}
            onChange={(_e, value) => {
              setPageOptions((prev) => ({
                ...prev,
                page: value,
              }));
            }}
          />
        </Stack>
      </CardActions>
    </Card>
  );
}

import i18n from 'src/locales/i18n';

import { TenantType } from 'src/types/enums';
import { PayPeriod } from 'src/types/payment';

import { Feature, Product, PriceTier, ProductType, Subscription } from './billing.types';

export const getActiveProduct = (
  subscription: Subscription,
  products: Product[]
): Product | undefined => {
  const productsToSearch = products.filter((product) => product.type === ProductType.Base);

  const subscriptionProductIds = subscription.products.map((subProduct) => subProduct.identifier);

  return productsToSearch.find((product) => subscriptionProductIds.includes(product.identifier));
};

export const getTenantFreeProduct = (tenant: TenantType, products: Product[]): Product => {
  const productsToSearch = products.filter(
    (product) => product.type === ProductType.Base && product.tenant_type === tenant
  );

  return productsToSearch.find((product) => !product.subscription_required) as Product;
};

export const getProductPrice = (
  product: Product | undefined,
  payPeriod: PayPeriod
): number | undefined =>
  product ? product.prices.find((price) => price.period === payPeriod)?.amount : undefined;

export const getProductTiers = (
  product: Product | undefined,
  payPeriod: PayPeriod
): PriceTier[] | undefined =>
  product ? product.prices.find((price) => price.period === payPeriod)?.tiers : undefined;

export const getProductFeature = (
  product: Product | undefined,
  key: string
): Feature | undefined =>
  product ? product.features.find((feature) => feature.type === key) : undefined;

export const getPayInvoiceErrorMessage = (e: any): string => {
  if (e?.data?.error_code === '3_3003') {
    return i18n.t('billing.api.invoices.pay.declined');
  }

  if (e?.data?.error_code === '3_2001') {
    return i18n.t('billing.api.invoices.pay.invalid');
  }

  return i18n.t('billing.api.invoices.pay.default_error');
};

export const getSubscriptionErrorMessage = (e: any): string => {
  if (e?.data?.error_code === '3_3002') {
    return i18n.t('billing.api.subscription.upgrade.declined');
  }

  if (e?.data?.error_code === '3_1002') {
    return i18n.t('billing.api.subscription.upgrade.no_subscription_required');
  }

  if (e?.data?.error_code === '3_5001') {
    return i18n.t('billing.api.subscription.upgrade.invalid_promo_code');
  }

  return i18n.t('billing.api.subscription.upgrade.default_error');
};

import { rootApi } from '../rootApi';
import { Contact } from '../contacts/contacts.types';
import { Application } from '../applications/applications.types';
import { orgBasePath } from '../organisation/organisation.service';
import { APIResponse, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  Connection,
  CompanyProfile,
  GetProfileById,
  ConnectionRequest,
  ProfileJobsResponse,
  GetConnectionsRequest,
  UnlinkConnectionProps,
  ConnectionSearchProps,
  GetConnectionDetailsProps,
  GetConnectionRequestProps,
  GetConnectionResourceProps,
  UpdateConnectionSettingsPops,
  InitiateConnectionRequestPops,
  ConnectionRequestActionPayload,
} from './connections.types';

export const connectionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrgConnections: builder.query<PaginatedResponse<Connection>, GetConnectionsRequest>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connections`,
        params: {
          page: data.page,
          per_page: data.per_page,
          search_query: data.search_query,
          status: data.status,
        },
      }),
      providesTags: ['Connections'],
    }),

    getConnectionById: builder.query<Connection, { organization_id: string; connection_id: string }>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connections/${data.connection_id}`,
      }),
      providesTags: (_res, _err, args) => [{ type: 'Connections', id: args.connection_id }],
    }),

    getConnectionRequestById: builder.query<ConnectionRequest, GetConnectionDetailsProps>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connection-requests/${data.request_id}`,
      }),
    }),

    actionConnectionRequest: builder.mutation<APIResponse, ConnectionRequestActionPayload>({
      query: (data) => ({
        url: `/connection-requests/${data.connectionId}/action`,
        method: HTTPRequestType.POST,
        data: data.action,
      }),
      invalidatesTags: ['Notifications', 'Connections', 'ConnectionRequests'],
    }),

    initiateConnectionRequest: builder.mutation<ConnectionRequest, InitiateConnectionRequestPops>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connection-requests`,
        method: HTTPRequestType.POST,
        data: data.request,
      }),
      invalidatesTags: ['ConnectionRequests'],
    }),

    updateConnectionSettings: builder.mutation<APIResponse, UpdateConnectionSettingsPops>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connections/${data.connection_id}/settings`,
        method: HTTPRequestType.PUT,
        data: data.settings,
      }),
      invalidatesTags: (_res, _err, args) => [{ type: 'Connections', id: args.connection_id }],
    }),

    cancelConnectionRequest: builder.mutation<APIResponse, { connectionId: string }>({
      query: (data) => ({
        url: `/connection-requests/${data.connectionId}/cancel`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['ConnectionRequests'],
    }),

    getConnectionRequests: builder.query<
      PaginatedResponse<ConnectionRequest>,
      GetConnectionRequestProps
    >({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connection-requests/${data.target}?page=${data.page}&per_page=${data.per_page}`,
      }),
      providesTags: ['ConnectionRequests'],
    }),

    unlinkConnection: builder.mutation<APIResponse, UnlinkConnectionProps>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connections/${data.connection_id}/disconnect`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Connections'],
    }),

    getIncomingConnectionRequests: builder.query<
      PaginatedResponse<ConnectionRequest>,
      GetConnectionsRequest
    >({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connection-requests/incoming?page=${data.page}&per_page=${data.per_page}`,
      }),
      providesTags: ['ConnectionRequests'],
    }),

    getOutgoingConnectionRequests: builder.query<
      PaginatedResponse<ConnectionRequest>,
      GetConnectionsRequest
    >({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connection-requests/outgoing?page=${data.page}&per_page=${data.per_page}${data.status ? `&status=${data.status}` : ''}`,
      }),
      providesTags: ['ConnectionRequests'],
    }),

    getConnectionRequestByToken: builder.query<ConnectionRequest, string>({
      query: (token) => ({
        url: `/connection-requests?identifier=${token}`,
      }),
    }),

    getInteractedOrganisations: builder.query<
      PaginatedResponse<CompanyProfile>,
      ConnectionSearchProps
    >({
      query: ({ params, target }) => ({
        url: `/my/${target}`,
        params,
      }),
      providesTags: (_res, _err, args) =>
        args.target === 'recruiters' ? ['Recruiters'] : ['Clients'],
    }),

    getConnectionJobs: builder.query<ProfileJobsResponse, GetConnectionResourceProps>({
      query: ({ profile_id, target, params }) => ({
        url: `/my/${target}/${profile_id}/jobs`,
        params,
      }),
    }),

    getConnectionApplications: builder.query<
      PaginatedResponse<Application>,
      GetConnectionResourceProps
    >({
      query: ({ profile_id, target, params }) => ({
        url: `/my/${target}/${profile_id}/job-applications`,
        params,
      }),
    }),

    getConnectionContacts: builder.query<PaginatedResponse<Contact>, GetConnectionResourceProps>({
      query: ({ profile_id, target, params }) => ({
        url: `/my/${target}/${profile_id}/contacts`,
        params,
      }),
    }),

    getConnectionProfileById: builder.query<CompanyProfile, GetProfileById>({
      query: ({ profile_id, target }) => ({
        url: `/my/${target}/${profile_id}`,
      }),
    }),
  }),
});

export const {
  useGetOrgConnectionsQuery,
  useLazyGetOrgConnectionsQuery,
  useActionConnectionRequestMutation,
  useLazyGetConnectionRequestByIdQuery,
  useInitiateConnectionRequestMutation,
  useUnlinkConnectionMutation,
  useCancelConnectionRequestMutation,
  useGetOutgoingConnectionRequestsQuery,
  useGetIncomingConnectionRequestsQuery,
  useLazyGetIncomingConnectionRequestsQuery,
  useLazyGetOutgoingConnectionRequestsQuery,
  useUpdateConnectionSettingsMutation,
  useLazyGetConnectionByIdQuery,
  useLazyGetConnectionRequestByTokenQuery,
  useGetInteractedOrganisationsQuery,
  useGetConnectionJobsQuery,
  useGetConnectionApplicationsQuery,
  useGetConnectionContactsQuery,
  useGetConnectionProfileByIdQuery,
} = connectionsApi;

import { IntegrationResource } from 'src/services/integration/integrations.types';

import { rootApi } from '../rootApi';
import { OrganisationDocument } from '../documents/documents.types';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  IJob,
  FeeType,
  JobInvite,
  JobSummary,
  CreateJobDTO,
  JobsResponse,
  LinkedAgency,
  ImportJobProps,
  JobLinkRequest,
  RequestJobLink,
  JobChecklistItem,
  JobImportResponse,
  GetJobsRequestProps,
  JobChecklistResponse,
  GetLinkedAgenciesProps,
  JobStakeholderResponse,
  UpdateRoleRequestProps,
  GetJobLinkRequestsProps,
  SendCandidateInviteProps,
  JobLinkActionRequestProps,
  JobInviteActionRequestProps,
  UpdateApplicationStatusProps,
  AssignStakeholderRequestProps,
  RemoveStakeholderRequestProps,
  UpdateStakeholderRequestProps,
  GetJobStakeholdersRequestProps,
} from './jobs.types';

export const jobsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyActiveJobs: builder.query<JobsResponse, GetJobsRequestProps | void>({
      query: (data?: GetJobsRequestProps) => ({
        url: `/jobs/my-jobs`,
        params: data,
      }),
      providesTags: ['Job'],
    }),

    getOrganisationJobs: builder.query<JobsResponse, GetJobsRequestProps | void>({
      query: (data?: GetJobsRequestProps) => ({
        url: `/jobs`,
        params: data,
      }),
      providesTags: ['Job'],
    }),

    createJob: builder.mutation<IJob, CreateJobDTO>({
      query: (data) => ({
        url: '/jobs',
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Job', 'Clients', 'Recruiters'],
    }),

    getJobById: builder.query<IJob, string>({
      query: (id) => ({
        url: `/jobs/${id}`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'Job', id: arg }],
    }),

    getJobSummary: builder.query<JobSummary, string>({
      query: (id) => ({
        url: `/jobs/${id}/summary`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'JobSummary', id: arg }],
    }),

    getJobActivities: builder.query<
      PaginatedResponse<IActivity>,
      { jobId: string; page: number; perPage: number }
    >({
      query: ({ jobId, perPage, page }) => ({
        url: `/jobs/${jobId}/activities`,
        params: {
          page,
          per_page: perPage,
        },
      }),
      providesTags: (_result, _error, arg) => [{ type: 'JobActivities', id: arg.jobId }],
    }),

    updateJob: builder.mutation<IJob, Partial<CreateJobDTO>>({
      query: (data) => ({
        url: `/jobs/${data.id}`,
        data,
        method: HTTPRequestType.PUT,
      }),
      invalidatesTags: (_result, _error, arg) => [
        'Job',
        { type: 'Job', id: arg.id },
        'Clients',
        'Recruiters',
      ],
    }),

    updateRoleData: builder.mutation<APIResponse, UpdateRoleRequestProps>({
      query: ({ jobId, data }) => ({
        url: `/jobs/${jobId}/role`,
        data,
        method: HTTPRequestType.PUT,
      }),
      invalidatesTags: ['Job'],
    }),

    requestJobLink: builder.mutation<IJob, RequestJobLink>({
      query: (request) => ({
        url: `/jobs/${request.job_id}/links/requests`,
        method: HTTPRequestType.POST,
        data: request.data,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Job', id: arg.job_id }],
    }),

    cancelVerificationRequests: builder.mutation<APIResponse, { job_id: string }>({
      query: (data) => ({
        url: `/jobs/${data.job_id}/links/verification-requests/cancel`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Job', id: arg.job_id }],
    }),

    getIncomingJobLinkRequests: builder.query<
      PaginatedResponse<JobLinkRequest>,
      GetJobLinkRequestsProps
    >({
      query: (params) => ({
        url: '/jobs/links/requests/incoming',
        params,
      }),
      providesTags: ['JobLinkRequest'],
    }),

    getOutgoingJobLinkRequests: builder.query<
      PaginatedResponse<JobLinkRequest>,
      GetJobLinkRequestsProps
    >({
      query: (params) => ({
        url: '/jobs/links/requests/outgoing',
        params,
      }),
      providesTags: ['JobLinkRequest'],
    }),

    getJobLinkRequestById: builder.query<JobLinkRequest, string>({
      query: (id: string) => ({
        url: `/jobs/links/requests/${id}`,
      }),
    }),

    actionJobLinkRequest: builder.mutation<APIResponse, JobLinkActionRequestProps>({
      query: (request) => ({
        url: `/jobs/links/requests/${request.requestId}/action`,
        data: request.data,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Job', 'JobLinkRequest', 'Clients', 'Recruiters'],
    }),

    approveJob: builder.mutation<APIResponse, string>({
      query: (jobId) => ({
        url: `/jobs/${jobId}/approve`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Job'],
    }),

    getLinkedAgencies: builder.query<PaginatedResponse<LinkedAgency>, GetLinkedAgenciesProps>({
      query: (data) => ({
        url: `/jobs/${data.jobId}/links`,
        params: data.pageOptions,
      }),
      providesTags: (_results, _error, _arg) => [{ type: 'LinkedAgencies', id: _arg.jobId }],
    }),

    updateJobApplicationStatus: builder.mutation<APIResponse, UpdateApplicationStatusProps>({
      query: (props) => ({
        url: `/jobs/${props.jobId}/application-status`,
        data: { status: props.status },
        method: HTTPRequestType.PUT,
      }),
      invalidatesTags: ['Job'],
    }),

    // Candidate Invites  ------------------------------------------------

    getCandidateInvitesByJob: builder.query<
      PaginatedResponse<JobInvite>,
      { jobId: string; page: number; perPage: number }
    >({
      query: (params) => ({
        url: `/jobs/${params.jobId}/invites`,
        params: {
          page: params.page,
          per_page: params.perPage,
        },
      }),
      providesTags: (_results, _error, _arg) => [{ type: 'JobInvites', id: _arg.jobId }],
    }),

    sendJobInvite: builder.mutation<APIResponse, SendCandidateInviteProps>({
      query: (props) => ({
        url: `/jobs/${props.jobId}/invites`,
        data: props.data,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: (_results, _error, _arg) => [
        { type: 'JobInvites', id: _arg.jobId },
        { type: 'JobSummary', id: _arg.jobId },
        'JobInvites',
      ],
    }),

    getCandidateInvites: builder.query<PaginatedResponse<JobInvite>, PageOptions>({
      query: (params) => ({
        url: '/jobs/invites/incoming',
        params,
      }),
      providesTags: ['JobInvites'],
    }),

    getCandidateInviteById: builder.query<JobInvite, string>({
      query: (id) => ({
        url: `/jobs/invites/${id}`,
      }),
    }),

    getOutgoingCandidateInvites: builder.query<
      PaginatedResponse<JobInvite>,
      PageOptions & { job_id?: string }
    >({
      query: (params) => ({
        url: '/jobs/invites/outgoing',
        params,
      }),
      providesTags: ['JobInvites'],
    }),

    cancelJobInvite: builder.mutation<APIResponse, string>({
      query: (inviteId) => ({
        url: `/jobs/invites/${inviteId}/cancel`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['JobInvites', 'JobSummary'],
    }),

    actionJobInvite: builder.mutation<APIResponse, JobInviteActionRequestProps>({
      query: (request) => ({
        url: `/jobs/invites/${request.requestId}/action`,
        data: request.data,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['JobInvites', 'Job', 'Clients', 'Recruiters'],
    }),

    importJob: builder.mutation<JobImportResponse, ImportJobProps>({
      query: (request) => ({
        url: `/jobs/import`,
        data: request.data,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Job', 'Clients', 'Recruiters'],
    }),

    archiveJob: builder.mutation<APIResponse, string>({
      query: (jobId) => ({
        url: `/jobs/${jobId}/archive`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Job'],
    }),

    getJobChecklist: builder.query<JobChecklistResponse, string>({
      query: (jobId) => ({
        url: `/jobs/${jobId}/checklist`,
      }),
      providesTags: ['Checklist'],
    }),

    updateJobChecklist: builder.mutation<
      APIResponse,
      { jobId: string; items: Pick<JobChecklistItem, 'id' | 'checked'>[] }
    >({
      query: ({ jobId, items }) => ({
        url: `/jobs/${jobId}/checklist/items`,
        method: HTTPRequestType.PUT,
        data: {
          items,
        },
      }),
      invalidatesTags: ['Checklist'],
    }),

    getJobDocuments: builder.query<OrganisationDocument[], string>({
      query: (jobId) => ({
        url: `/jobs/${jobId}/documents`,
      }),
      providesTags: ['Documents'],
    }),

    addDocumentToJob: builder.mutation<APIResponse, { jobId: string; documentId: string }>({
      query: ({ jobId, documentId }) => ({
        url: `/jobs/${jobId}/documents/add`,
        method: HTTPRequestType.POST,
        data: { document_id: documentId },
      }),
      invalidatesTags: ['Documents'],
    }),

    getJobStakeholders: builder.query<
      PaginatedResponse<JobStakeholderResponse>,
      GetJobStakeholdersRequestProps
    >({
      query: (data) => ({
        url: `/jobs/${data.job_id}/stakeholders`,
        params: {
          page: data.page,
          per_page: data.per_page,
          search_query: data.search_query,
        },
      }),
      providesTags: ['Stakeholders'],
    }),

    addStakeholderToJob: builder.mutation<APIResponse, AssignStakeholderRequestProps>({
      query: ({ job_id, ...data }) => ({
        url: `/jobs/${job_id}/stakeholders/add`,
        method: HTTPRequestType.POST,
        data: {
          account_id: data.account_id,
          labels: data.labels,
          visibility: data.visibility,
        },
      }),
      invalidatesTags: ['Stakeholders'],
    }),

    updateJobStakeholder: builder.mutation<APIResponse, UpdateStakeholderRequestProps>({
      query: ({ job_id, account_id, ...data }) => ({
        url: `/jobs/${job_id}/stakeholders/${account_id}`,
        method: HTTPRequestType.PUT,
        data: {
          labels: data.labels,
          visibility: data.visibility,
        },
      }),
      invalidatesTags: ['Stakeholders'],
    }),

    removeStakeholderFromJob: builder.mutation<APIResponse, RemoveStakeholderRequestProps>({
      query: ({ job_id, ...data }) => ({
        url: `/jobs/${job_id}/stakeholders/remove`,
        method: HTTPRequestType.POST,
        data: {
          account_id: data.account_id,
        },
      }),
      invalidatesTags: ['Stakeholders'],
    }),

    // integrations
    getJobIntegrations: builder.query<IntegrationResource[], { job_id: string }>({
      query: ({ job_id }) => ({
        url: `/jobs/${job_id}/integrations`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'JobIntegrations', id: arg.job_id }],
    }),

    requestJobChanges: builder.mutation<APIResponse, { job_id: string; notes: string }>({
      query: ({ job_id, notes }) => ({
        url: `/jobs/${job_id}/request-job-update`,
        method: HTTPRequestType.POST,
        data: { notes },
      }),
      invalidatesTags: ['Job'],
    }),

    rejectJobChanges: builder.mutation<APIResponse, { job_id: string }>({
      query: ({ job_id }) => ({
        url: `/jobs/${job_id}/reject-job-update`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Job'],
    }),

    // fee settings
    updateFeeSettings: builder.mutation<APIResponse, { job_id: string; organization_id: string; fee_type: FeeType; fee_value: number }>({
      query: ({ job_id, organization_id, fee_type, fee_value }) => ({
        url: `/jobs/${job_id}/links/${organization_id}/fee-settings`,
        method: HTTPRequestType.PUT,
        data: { fee_type, fee_value },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'LinkedAgencies', id: arg.job_id }]
    })
  })
});

export const {
  useGetMyActiveJobsQuery,
  useGetOrganisationJobsQuery,
  useCreateJobMutation,
  useGetJobByIdQuery,
  useGetJobSummaryQuery,
  useGetJobActivitiesQuery,
  useUpdateJobMutation,
  useRequestJobLinkMutation,
  useCancelVerificationRequestsMutation,
  useGetIncomingJobLinkRequestsQuery,
  useGetOutgoingJobLinkRequestsQuery,
  useGetJobLinkRequestByIdQuery,
  useActionJobLinkRequestMutation,
  useApproveJobMutation,
  useGetLinkedAgenciesQuery,
  useUpdateJobApplicationStatusMutation,
  useGetCandidateInvitesByJobQuery,
  useSendJobInviteMutation,
  useGetCandidateInvitesQuery,
  useActionJobInviteMutation,
  useGetOutgoingCandidateInvitesQuery,
  useCancelJobInviteMutation,
  useImportJobMutation,
  useArchiveJobMutation,
  useGetJobChecklistQuery,
  useUpdateJobChecklistMutation,
  useGetJobDocumentsQuery,
  useAddDocumentToJobMutation,
  useLazyGetJobStakeholdersQuery,
  useGetJobStakeholdersQuery,
  useAddStakeholderToJobMutation,
  useUpdateJobStakeholderMutation,
  useRemoveStakeholderFromJobMutation,
  useLazyGetCandidateInviteByIdQuery,
  useGetCandidateInviteByIdQuery,
  useUpdateRoleDataMutation,

  useGetJobIntegrationsQuery,

  // Job Changes
  useRequestJobChangesMutation,
  useRejectJobChangesMutation,

  // Fee Settings
  useUpdateFeeSettingsMutation
} = jobsApi;

import { useSnackbar } from 'notistack';
import { Fragment, useCallback } from 'react';

import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  List,
  Stack,
  Avatar,
  Button,
  Divider,
  ListItem,
  Pagination,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { PageOptions, PaginatedResponse } from 'src/services/api.types';
import { showConnectionActionModal } from 'src/store/slices/connections/connectionsSlice';
import { useActionConnectionRequestMutation, useCancelConnectionRequestMutation } from 'src/services/connections/connections.service';
import {
  RequestStatus,
  ConnectionRequest,
  RequestInviteCategory,
  ConnectionActionOption,
} from 'src/services/connections/connections.types';

import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import Label, { LabelProps } from 'src/components/label';

import { TenantType } from 'src/types/enums';

// ----------------------------------------------------------------------

export type ConnectionRequestType = 'incoming' | 'pending' | 'unapproved';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  type: ConnectionRequestType;
  requests?: PaginatedResponse<ConnectionRequest>;
  page: PageOptions;
  changePage: (value: number) => void;
};

export default function ManageConnectionRequestsModal({
  open,
  onClose,
  type,
  requests,
  page,
  changePage,
}: Props) {
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const tenant = useOrgTenant();

  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const [actionConnectionRequest, { isLoading: isActioning }] =
    useActionConnectionRequestMutation();

  const [cancelConnectionRequest, { isLoading: isCancelling }] = useCancelConnectionRequestMutation();

  const onActionConnectionRequest = useCallback(
    async (request: ConnectionRequest, action: ConnectionActionOption) => {
      try {
        await actionConnectionRequest({
          connectionId: request.id,
          action: {
            action,
          },
        }).unwrap();

        enqueueSnackbar(t(`admin-connections.api.action.success-${action}`));
      } catch (e) {
        console.error(e);

        enqueueSnackbar(t(`admin-connections.api.action.default_error`));
      }
    },
    [actionConnectionRequest, enqueueSnackbar, t]
  );

  const handleCancelRequest = useCallback(
    async (requestId: string) => {
      try {

        await cancelConnectionRequest({ connectionId: requestId }).unwrap();

        enqueueSnackbar(t('admin-connections.requests.cancel.api.success'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t('admin-connections.requests.cancel.api.default_error'), { variant: 'error' });
      }
    },
    [enqueueSnackbar, t, cancelConnectionRequest]
  );

  const onManageRequest = (connectionId: string) => {
    onClose();

    dispatch(showConnectionActionModal({ requestId: connectionId }));

    router.push(paths.dashboard.admin.connection_management.root);
  };

  const renderOutgoingButtons = (connectionRequest: ConnectionRequest) => {
    const isUnapproved = connectionRequest.status === RequestStatus.UNAPPROVED;

    if (isUnapproved) {
      return (
        <>
          <LoadingButton
            variant="contained"
            loading={isActioning}
            sx={{ mr: 1 }}
            color="success"
            aria-label="approve"
            size='small'
            onClick={() =>
              onActionConnectionRequest(connectionRequest, ConnectionActionOption.Approve)
            }
          >
            {t('common.approve')}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={isActioning}
            size='small'
            aria-label="deny"
            onClick={() =>
              onActionConnectionRequest(connectionRequest, ConnectionActionOption.Deny)
            }
          >
            {t('common.deny')}
          </LoadingButton>
          <IconButton onClick={() => handleCancelRequest(connectionRequest.id)} disabled={isCancelling}>
            <Iconify icon="gg:trash" color="red" />
          </IconButton>
        </>
      );
    }

    return <>
      <ConnectionRequestStatusLabel status={connectionRequest.status} />
      <IconButton onClick={() => handleCancelRequest(connectionRequest.id)} disabled={isCancelling}>
        <Iconify icon="gg:trash" color="red" />
      </IconButton>
    </>;
  };

  const renderIncomingButtons = (connectionRequest: ConnectionRequest) => (
    <Button
      variant="contained"
      sx={{ mr: 1 }}
      aria-label="delete"
      onClick={() => onManageRequest(connectionRequest.id)}
    >
      {t('common.manage')}
    </Button>
  );

  const getPrimaryInvitee = (connectionRequest: ConnectionRequest) => {
    if (Array.isArray(connectionRequest.invites)) {
      const primary = connectionRequest.invites.find((invite) => invite.category === RequestInviteCategory.PRIMARY);
      if (primary) return t('admin-connections.requests.sent_to', { target: primary.email })
    }

    return t('admin-connections.requests.sent_to', { target: t('common.administrator') });
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {type} {t('common.connection')} {t('common.requests')}
      </DialogTitle>

      <DialogContent>
        <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
          {t(`admin-connections.${type}_modal.alert`)}
        </Alert>

        {
          requests?.count ? (
            <List dense>
              {requests?.results?.map((request, index) => (
                <Fragment key={`request-${index}`}>
                  <ListItem
                    sx={{ mb: 1 }}
                    secondaryAction={
                      type === "incoming"
                        ? renderIncomingButtons(request)
                        : renderOutgoingButtons(request)
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {request[
                          tenant === TenantType.Client ? TenantType.Recruiter : TenantType.Client
                        ].company_name.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondaryTypographyProps={{
                        paddingRight: 14,
                      }}
                      primary={
                        request[tenant === TenantType.Client ? TenantType.Recruiter : TenantType.Client]
                          .company_name
                      }
                      secondary={getPrimaryInvitee(request)}
                    />
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </Fragment>
              ))}
            </List>
          ) : (
            <EmptyContent
              filled
              title={t('common.no_requests')}
              sx={{
                py: 10,
              }}
            />
          )
        }

        <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
          <Pagination
            page={page.page || 1}
            count={Math.ceil((requests?.count || 1) / page.per_page)}
            onChange={(_e, value) => changePage(value)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export function ConnectionRequestStatusLabel(props: LabelProps & { status: RequestStatus }) {
  const { status } = props;
  const warningStatus = [RequestStatus.PENDING, RequestStatus.UNAPPROVED];
  const errorStatus = [RequestStatus.REJECTED, RequestStatus.DENIED];
  const infoStatus = [RequestStatus.CLOSED];

  return (
    <Label
      variant="soft"
      color={
        (warningStatus.includes(status) && 'warning') ||
        (errorStatus.includes(status) && 'error') ||
        (infoStatus.includes(status) && 'info') ||
        'success'
      }
    >
      <Typography
        variant="subtitle2"
        fontSize={11}
        fontWeight="700"
        sx={{ textTransform: 'uppercase' }}
      >
        {status}
      </Typography>
    </Label>
  );
}

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { Tooltip, IconButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import {
  getJobInviteNotificationMessage,
  getInterviewNotificationMessage,
  getConnectionNotificationMessage,
  getApplicationNotificationMessage,
  getJobLinkRequestNotificationMessage,
  getInterviewReviewNotificationMessage,
} from 'src/utils/notifications.utils';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { isAdmin, isOrganisationTenant } from 'src/services/auth/auth.utils';
import { ConnectionActionOption } from 'src/services/connections/connections.types';
import { openInterviewActionModal } from 'src/store/slices/interviews/interviewSlice';
import { showConnectionActionModal } from 'src/store/slices/connections/connectionsSlice';
import { useMarkAllAsReadMutation } from 'src/services/notifications/notifications.service';
import { useActionConnectionRequestMutation } from 'src/services/connections/connections.service';
import {
  setShowOfferModal,
  setShowActionStageRequestModal,
} from 'src/store/slices/applications/applicationsSlice';
import {
  Notification,
  NotificationType,
  NotificationCategory,
} from 'src/services/notifications/notifications.types';
import {
  showJobLinkActionModal,
  showJobVerificationModal,
  showCandidateJobRequestsModal,
} from 'src/store/slices/jobs/jobsSlice';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: Notification;
  closeDrawer: VoidFunction;
};

// const getNotificationTitle = (notification: Notification) => {
//   if (notification.category === NotificationCategory.JOB) {
//     return i18n.t()
//   }
// }

const getNotificationSubtitle = (notification: Notification) => {
  if (notification.category === NotificationCategory.CONNECTION) {
    return getConnectionNotificationMessage(notification);
  }

  if (notification.category === NotificationCategory.JOB) {
    return getJobLinkRequestNotificationMessage(notification);
  }

  if (notification.category === NotificationCategory.JOB_INVITE) {
    return getJobInviteNotificationMessage(notification);
  }

  if (notification.category === NotificationCategory.JOB_APPLICATION) {
    return getApplicationNotificationMessage(notification);
  }

  if (notification.category === NotificationCategory.INTERVIEW) {
    return getInterviewNotificationMessage(notification);
  }

  if (notification.type === NotificationType.INTERVIEW_IN_REVIEW) {
    return getInterviewReviewNotificationMessage(notification);
  }

  return '';
};

export default function NotificationItem({ notification, closeDrawer }: NotificationItemProps) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const authContext = useAuthContext();

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const router = useRouter();

  const [actionConnectionRequest, { isLoading: isRejectingConnection }] =
    useActionConnectionRequestMutation();

  const [markAsRead] = useMarkAllAsReadMutation();

  const onAcceptConnectionRequest = useCallback(
    (connectionId: string) => {
      dispatch(
        showConnectionActionModal({ requestId: connectionId, notificationId: notification.id })
      );

      closeDrawer();
    },
    [closeDrawer, dispatch, notification.id]
  );

  const onRejectConnectionRequest = useCallback(
    async (connectionId: string) => {
      try {
        await actionConnectionRequest({
          connectionId,
          action: {
            action: ConnectionActionOption.Reject,
          },
        }).unwrap();

        closeDrawer();
        markAsRead({ notifications: [notification.id] });
        enqueueSnackbar(t(`admin-connections.api.action.success-${ConnectionActionOption.Reject}`));
      } catch (e) {
        console.error(e);

        enqueueSnackbar(t('admin-connections.api.action.default_error'), { variant: 'error' });
      }
    },
    [actionConnectionRequest, closeDrawer, enqueueSnackbar, markAsRead, notification.id, t]
  );

  const renderAvatar = (
    <ListItemAvatar>
      {/* {notification.avatarUrl ? (
        <Avatar src={notification.avatarUrl} sx={{ bgcolor: 'background.neutral' }} />
      ) : ( */}
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        <Box
          component="img"
          src="/assets/icons/notification/ic_mail.svg"
          sx={{ width: 24, height: 24 }}
        />
      </Stack>
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      primary={reader(notification.metadata.title ?? 'New Notification')}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {/* {fToNow(notification.metadata.)} */}
          {getNotificationSubtitle(notification)}
        </Stack>
      }
    />
  );

  <Box
    sx={{
      top: 26,
      width: 8,
      height: 8,
      right: 20,
      borderRadius: '50%',
      bgcolor: 'info.main',
      position: 'absolute',
    }}
  />;

  const applicationRequestAction = (notificationObj: Notification) => (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          dispatch(
            setShowActionStageRequestModal(
              notificationObj.metadata?.variables?.application_id as string
            )
          );
          closeDrawer();
          markAsRead({ notifications: [notificationObj.id] });
        }}
      >
        {t('common.view')}
      </Button>
    </Stack>
  );

  const offerChangesRequestedAction = (notificationObj: Notification) => (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          dispatch(
            setShowOfferModal({
              applicationId: notificationObj.metadata?.variables?.application_id as string,
              offerId: notificationObj.metadata?.variables?.offer_id as string,
            })
          );
          closeDrawer();
          markAsRead({ notifications: [notificationObj.id] });
        }}
      >
        {t('common.view')}
      </Button>
    </Stack>
  );

  const connectionAction = (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        disabled={!isAdmin(authContext)}
        onClick={() =>
          onAcceptConnectionRequest(notification.metadata?.variables?.request_id as string)
        }
      >
        {t('common.accept')}
      </Button>
      <LoadingButton
        size="small"
        variant="outlined"
        disabled={!isAdmin(authContext)}
        onClick={() =>
          onRejectConnectionRequest(notification.metadata?.variables?.request_id as string)
        }
        loading={isRejectingConnection}
      >
        {t('common.decline')}
      </LoadingButton>
    </Stack>
  );

  const jobChangeRequestAction = (notificationObj: Notification) => (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          router.push(
            `${paths.dashboard.jobs.edit(notificationObj.metadata?.variables?.job_id as string)}`
          );
          closeDrawer();
          markAsRead({ notifications: [notificationObj.id] });
        }}
      >
        {t('common.view')}
      </Button>
    </Stack>
  );

  const jobLinkAction = (notificationObj: Notification) => {
    if (notification.type === NotificationType.JOB_CLIENT_VERIFIED) {
      return (
        <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              router.push(
                `${paths.dashboard.jobs.root}/${
                  notificationObj.metadata?.variables?.job_id as string
                }`
              );
              closeDrawer();
              markAsRead({ notifications: [notification.id] });
            }}
          >
            {t('common.view')}
          </Button>
        </Stack>
      );
    }

    if (notification.type === NotificationType.JOB_LINK_REQUEST_INITIATED) {
      return (
        <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              dispatch(
                showJobLinkActionModal({
                  requestId: notificationObj.metadata?.variables?.link_request_id as string,
                  notificationId: notification.id,
                })
              );

              closeDrawer();
            }}
          >
            {`${t('common.manage')}`}
          </Button>
        </Stack>
      );
    }

    if (notification.type === NotificationType.JOB_REQUIRES_APPROVAL) {
      return (
        <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              dispatch(showJobVerificationModal());
              closeDrawer();
              markAsRead({ notifications: [notification.id] });
            }}
          >
            {`${t('common.manage')}`}
          </Button>
        </Stack>
      );
    }

    return null;
  };

  const jobInviteAction = (notificationObj: Notification) => {
    if (notification.type === NotificationType.JOB_INVITE_INITIATED) {
      return (
        <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              dispatch(showCandidateJobRequestsModal());
              closeDrawer();
              markAsRead({ notifications: [notificationObj.id] });
            }}
          >
            {`${t('common.view')}`}
          </Button>
        </Stack>
      );
    }

    return null;
  };

  const interviewReviewAction = (notificationObj: Notification) => (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          dispatch(
            openInterviewActionModal({
              interviewId: notificationObj.metadata?.variables?.interview_id as string,
            })
          );
          closeDrawer();
          markAsRead({ notifications: [notificationObj.id] });
        }}
      >
        {`${t('common.view')}`}
      </Button>
    </Stack>
  );

  const renderApplicationCategoryNotification = (notificationObj: Notification) => {
    if (notificationObj.metadata?.variables?.application_id) {
      // eslint-disable-next-line consistent-return
      return (
        <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              if (isOrganisationTenant(tenant)) {
                router.push(
                  `${paths.dashboard.jobs.application(
                    notificationObj.metadata?.variables?.job_id as string,
                    notificationObj.metadata?.variables?.application_id as string
                  )}`
                );
              } else if (notificationObj.metadata?.variables?.job_id) {
                router.push(
                  `${paths.dashboard.jobs.detail(
                    notificationObj.metadata?.variables?.job_id as string
                  )}`
                );
              }
              closeDrawer();
              markAsRead({ notifications: [notificationObj.id] });
            }}
          >
            {t('common.view')}
          </Button>
        </Stack>
      );
    }

    if (!isOrganisationTenant(tenant) && notificationObj.metadata?.variables?.job_id) {
      return (
        <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              if (isOrganisationTenant(tenant))
                router.push(
                  `${paths.dashboard.jobs.detail(
                    notificationObj.metadata?.variables?.job_id as string
                  )}`
                );
              closeDrawer();
              markAsRead({ notifications: [notificationObj.id] });
            }}
          >
            {t('common.view')}
          </Button>
        </Stack>
      );
    }

    return null;
  };

  const renderInterviewNotification = (notificationObj: Notification) => (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          dispatch(
            openInterviewActionModal({
              interviewId: notificationObj.metadata?.variables?.interview_id as string,
            })
          );
          closeDrawer();
          markAsRead({ notifications: [notificationObj.id] });
        }}
      >
        {t('common.view')}
      </Button>
    </Stack>
  );

  const renderApplicationLink = (
    <Button
      size="small"
      variant="contained"
      onClick={() => {
        router.push(
          `${paths.dashboard.jobs.application(
            notification.metadata?.variables?.job_id as string,
            notification.metadata?.variables?.application_id as string
          )}`
        );
        closeDrawer();
        markAsRead({ notifications: [notification.id] });
      }}
    >
      {t('common.view')}
    </Button>
  );

  const renderAction = () => {
    // Notification Type specific actions are checked first.
    switch (notification.type) {
      case NotificationType.APPLICATION_OFFER_CHANGES_REQUESTED:
        return offerChangesRequestedAction(notification);
      case NotificationType.APPLICATION_OFFER_CREATED:
        return offerChangesRequestedAction(notification);
      case NotificationType.APPLICATION_STAGE_CHANGE_REQUEST_INITIATED:
        return applicationRequestAction(notification);
      case NotificationType.INITIATE_CONNECTION_REQUEST:
        return connectionAction;
      case NotificationType.JOB_INVITE_ACCEPTED:
        return renderApplicationLink;
      case NotificationType.JOB_CHANGES_REQUESTED:
        return jobChangeRequestAction(notification);
      case NotificationType.INTERVIEW_IN_REVIEW:
        return interviewReviewAction(notification);
      default:
        break;
    }

    switch (notification.category) {
      case NotificationCategory.JOB:
        return jobLinkAction(notification);
      case NotificationCategory.JOB_INVITE:
        return jobInviteAction(notification);
      case NotificationCategory.JOB_APPLICATION:
        return renderApplicationCategoryNotification(notification);
      case NotificationCategory.INTERVIEW:
        return renderInterviewNotification(notification);
      default:
        return null;
    }
  };

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {/* {renderUnReadBadge} */}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }} gap={1} direction="row">
        {renderText}

        <Stack direction="column" justifyContent="space-between" alignItems="flex-end">
          <Tooltip title={t('notifications.popover.mark_read')}>
            <IconButton
              style={{
                width: 40,
                height: 40,
              }}
              size="medium"
              onClick={() => {
                markAsRead({ notifications: [notification.id] });
              }}
            >
              <Iconify icon="solar:check-read-line-duotone" />
            </IconButton>
          </Tooltip>

          {renderAction()}
        </Stack>
      </Stack>
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function reader(data: string) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}

import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

import { Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { Category } from 'src/services/utils/utils.types';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useUpdateConnectionSettingsMutation } from 'src/services/connections/connections.service';
import {
  Connection,
  UpdateConnectionSettingsPops,
} from 'src/services/connections/connections.types';

import { SettingsFormData, ConnectionSettingsForm } from 'src/sections/connections/modal/create-connection-modal/components/connection-settings-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  connection: Connection;
  onClose: VoidFunction;
};

const generateErrorMessage = (e: any) => i18n.t('admin-connections.api.update.default_error');

// ----------------------------------------------------------------------

export default function EditConnectionModal({ open, connection, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const authContext = useAuthContext();

  const [formValues, setFormValues] = React.useState<SettingsFormData>();

  const [updateConnectionSettings, { isLoading: isUpdating }] = useUpdateConnectionSettingsMutation();

  const defaultValues = {
    job_limit_applied: !!connection.job_limit,
    jobs_limit: connection.job_limit ?? 0,
    specializations: [...connection.approved_specializations],
    default_fee_type: connection.default_fee_type,
    default_fee_value: connection.default_fee_value,
  };

  const onCloseClicked = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleUpdateSettings = useCallback(async () => {
    if (!formValues) return;

    const requestData: UpdateConnectionSettingsPops = {
      organization_id: authContext.organization.id,
      connection_id: connection.id,
      settings: {
        job_limit_applied: formValues.job_limit_applied,
        jobs_limit: formValues.jobs_limit ?? 0,
        specializations: (formValues.specializations as Category[]).map((item) => item.id),
        default_fee_type: formValues.default_fee_type,
        default_fee_value: formValues.default_fee_value,
      },
    };
    try {
      await updateConnectionSettings(requestData).unwrap();

      enqueueSnackbar(t('admin-connections.api.update.success'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(generateErrorMessage(e), { variant: 'error' });
    }

    onClose();
  }, [enqueueSnackbar, updateConnectionSettings, connection, authContext.organization.id, t, formValues, onClose]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseClicked}>

      <DialogTitle>{t('admin-connections.connection_settings.title')}</DialogTitle>

      <DialogContent>
        <Stack flexDirection="column">
          <ConnectionSettingsForm
            onValuesChange={setFormValues}
            defaultValues={defaultValues}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant="outlined" onClick={onCloseClicked} loading={isUpdating}>
          {t('common.cancel')}
        </LoadingButton>

        <LoadingButton
          key="submit-btn"
          onClick={handleUpdateSettings}
          variant="contained"
          loading={isUpdating}
        >
          {t('common.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import { useTranslate } from 'src/locales';

import ApplicationProfileResumeUploader from 'src/components/profile-importer/profile-resume-importer';

type Props = {
  applicationId: string;
  open: boolean;
  onClose: VoidFunction;
};

export default function ProfileImporterDialog({ applicationId, open, onClose }: Props) {
  const { t } = useTranslate();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('applications.candidate_resume_importer.modal.title')}</DialogTitle>
      <DialogContent>
        <ApplicationProfileResumeUploader applicationId={applicationId} showWarning onSuccess={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}

import _, { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { useMemo, useState, useCallback } from 'react';

import {
  Card,
  Chip,
  Table,
  Stack,
  Tooltip,
  TableRow,
  useTheme,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Typography,
  IconButton,
  CardContent,
  useMediaQuery,
  TableContainer,
  InputAdornment,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { AdminOrganization } from 'src/services/admin/admin.types';
import { useGetAllOrganizationsQuery } from 'src/services/admin/admin.service';
import { ProductType, SubscriptionStatus } from 'src/services/billing/billing.types';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import DateDisplay from 'src/components/date/date-display';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { OrganizationStatusLabel } from 'src/sections/admin/organization/organization-status-label';

import { TenantType } from 'src/types/enums';

const default_page: PageOptions = {
  page: 1,
  per_page: 20
};

export default function GlobalOrganizationsTable() {
  const table = useTable();

  const theme = useTheme();

  const { t } = useTranslate();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [pageOptions, setPageOptions] = useState<PageOptions>(default_page);

  const [searchQuery, setSearchQuery] = useState('');
  const [filterTenantType, setFilterTenantType] = useState<TenantType | string>('');

  const { currentData, isLoading } = useGetAllOrganizationsQuery({
    ...pageOptions,
    search_query: searchQuery,
    tenant_type: filterTenantType as TenantType,
  });

  const denseHeight = table.dense ? 56 : 56 + 20;

  const tableLabels = useMemo(
    () => [
      { id: 'company_name', label: 'Organization Name' },
      { id: 'type', label: 'Type' },
      { id: 'subscription', label: 'Subscription' },
      { id: 'status', label: 'Status' },
      { id: 'created_at', label: 'Joined On' },
      { id: 'actions', label: 'Actions' },
    ],
    []
  );

  const notFound = !currentData?.count;

  const onChangePage = (_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({ ...prev, page: pageNumber + 1 }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageOptions((prev) => ({ ...prev, per_page: parseInt(event.target.value, 10) }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 500),
    [setSearchQuery]
  );

  const onClearFilters = () => {
    setFilterTenantType('');
    setSearchQuery('');
  };

  return (
    <Card>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            gap={2}
            sx={{
              p: 2.5,
              pr: { xs: 2.5, md: 1 },
            }}
          >
            <TextField
              onChange={(e) => debounceSearch(e.target.value)}
              label={t('common.search')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              value={filterTenantType}
              label={t('common.tenant_type')}
              select
              sx={{
                minWidth: 200,
              }}
              onChange={(event) => setFilterTenantType(event.target.value as TenantType)}
              placeholder={t('common.tenant_type')}
            >
              <MenuItem value="">{t('common.none')}</MenuItem>
              <MenuItem key={TenantType.Candidate} value={TenantType.Candidate}>
                {t(`enums.tenant.${TenantType.Candidate}`)}
              </MenuItem>
              <MenuItem key={TenantType.Recruiter} value={TenantType.Recruiter}>
                {t(`enums.tenant.${TenantType.Recruiter}`)}
              </MenuItem>
              <MenuItem key={TenantType.Client} value={TenantType.Client}>
                {t(`enums.tenant.${TenantType.Client}`)}
              </MenuItem>
            </TextField>

            <Tooltip title={t('restricted.organizations.table.filters.clear')}>
              <IconButton
                size="large"
                onClick={onClearFilters}
                aria-label="Clear job filters"
              >
                <Iconify icon="material-symbols:cancel" />
              </IconButton>
            </Tooltip>
          </Stack>

          <TableContainer sx={{ overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>

                {
                  !isMobile && (
                    <TableHeadCustom headLabel={tableLabels} />
                  )
                }

                <TableBody>
                  {currentData?.results?.map((row) => <OrganizationsTableRow key={row.id} row={row} />)}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, currentData?.count ?? 0)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentData?.count ?? 0}
            page={pageOptions.page}
            rowsPerPage={pageOptions.per_page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </>
      )
      }
    </Card>
  );
}

const OrganizationsTableRow = ({ row }: { row: AdminOrganization }) => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const router = useRouter();

  const { t } = useTranslate();

  const organizationName = useMemo(() => {
    if (row.type === TenantType.Candidate) {
      return `${row.candidate_profile?.first_name ?? ''} ${row.candidate_profile?.last_name ?? '-'}`;
    }
    if ([TenantType.Client, TenantType.Recruiter].includes(row.type)) {
      return row.company_profile?.company_name;
    }

    return '';
  }, [row]);

  const viewOrganization = useCallback((id: string) => {
    router.push(`${paths.dashboard.restricted.tenants.root}/${id}`);
  }, [router]);

  const isSubscriptionActive = useMemo(() => row.subscriptions?.some(sub => sub.status === SubscriptionStatus.ACTIVE), [row]);

  const subscribedProductName = useMemo(() => {
    if (!isSubscriptionActive) return '';

    const basePlan = row.products?.find(product => product.type === ProductType.Base);

    if (!basePlan) return '';

    return `${_.startCase(basePlan.identifier)} x${basePlan.quantity}`;
  }, [row, isSubscriptionActive]);

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent >
          <Stack direction="column" alignItems="start" gap={0} mb={1}>
            <Link
              to={`${paths.dashboard.restricted.tenants.root}/${row.id}`}
              style={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
              }}
            >
              <Typography variant="h6" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                {organizationName}
              </Typography>
            </Link>
            <Typography variant="caption" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
              {row.type}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <OrganizationStatusLabel status={row.status} />
            </Stack>
          </Stack>
        </CardContent>

      </Card>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Link
          style={{ color: theme.palette.text.primary }}
          to={`${paths.dashboard.restricted.tenants.root}/${row.id}`}
        >
          {organizationName}
        </Link>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{_.capitalize(row.type)}</Typography>
      </TableCell>
      <TableCell>
        {
          isSubscriptionActive ? (
            <Tooltip title={subscribedProductName}>
              <Chip label={t(`billing.subscription.status.active`)} color="success" variant='outlined' size='small' />
            </Tooltip>
          ) : (
            <Chip label={t(`billing.subscription.status.inactive`)} color="default" variant='outlined' size='small' />
          )
        }
      </TableCell>
      <TableCell>
        <OrganizationStatusLabel status={row.status} />
      </TableCell>
      <TableCell>
        <DateDisplay date={row.created_at as Date} variant='caption' />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => viewOrganization(row.id)} size="small">
          <Iconify icon="carbon:view-filled" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};


